var TABLE_CP_REGS = {};

/**
  * Calculates table columns and rows
  * @param {BlockKT[]} [blocks] array of blocks
  * @param {Number} [mcd] maximum common divisor of surgery durations
  * @return {Object} object with unique ORs and numRows= max Duration / mcd
  */
TABLE_CP_REGS.calculateTableDimensions = function(blocks, mcd) {

  let maxDur    = 0,
      uniqueORs = [];
  for (let i=0, len=blocks.length; i<len; i++) {

    if (uniqueORs.indexOf(blocks[i].or) === -1)
      uniqueORs.push(blocks[i].or);

    if (blocks[i].duration > maxDur)
      maxDur = blocks[i].duration;
  }
  return {
    uniqueORs: uniqueORs,
    numCols: uniqueORs.length,
    numRows: maxDur / mcd
  };
}

/**
  * Writes the head of the results table, based on the number of ORs
  * @param {Number} [numCols] the number of ORs
  * @param {Number[]} [cols] array of column labels
  * @param {String} [idTable] table id
  */
TABLE_CP_REGS.writeResHead = function(numCols, cols, idTable) {

  let tr    = document.getElementById(idTable).tHead,
      th    = document.createElement('th');
  for (let i=0; i<numCols; i++) {

    th = document.createElement('th');
    th.setAttribute('style', 'text-align: center');
    th.innerHTML = cols[i];
    tr.appendChild(th);
  }
};

/**
  * Writes the body of the table
  * @param {Number} [numRows] number of rows
  * @param {BlockKT[]} [blocks] array of blocks
  * @param {Number} [numCols] number of columns
  * @param {String} [idTable] table id
  * @param {Number} [mcd] maximum common divisor of surgery durations
  * @param {Array} [seq] sequence of colors (in hex format) for the blocks
  * @param {Array} [specSeq] sequence of colors (in hex format) for the specialties
  */
TABLE_CP_REGS.writeResBody = function(numRows, blocks, numCols, idTable, mcd, seq, specSeq) {

  let tbody          = document.getElementById(idTable).tBodies[0],
      tdCounter      = new Array(numCols).fill(0),
      emptySpaceFlag = new Array(numCols).fill(false),
      assRegsCounter = new Array(numCols).fill(0),
      hour           = (blocks[0].turn % 2) ? 8 : 13,
      tr, td, ri, rs;

  for (let i=0; i<numRows; i++) {

    tr = document.createElement('tr');
    tr.setAttribute('style', 'line-height: 0px');
    td = document.createElement('td');
    if (i % 6 === 0) {
      //td.setAttribute('rowspan', `6`);
      td.innerHTML = hour + ':00';
      hour++;
    }
    tr.appendChild(td);
    for (let j=0; j<numCols; j++) {

      if (blocks[j].assRegs[assRegsCounter[j]]) {

        if (!tdCounter[j]) {
          td = document.createElement('td');
          td.innerHTML = blocks[j].assRegs[assRegsCounter[j]].onFirstDay ? `${blocks[j].assRegs[assRegsCounter[j]].id}*` : `${blocks[j].assRegs[assRegsCounter[j]].id}`;
          td.classList.add('block');
          td.setAttribute('data-toggle', 'tooltip');
          td.setAttribute('data-placement', 'right');
          td.setAttribute('title',
                          `id: ${blocks[j].assRegs[assRegsCounter[j]].id} \npriority: ${blocks[j].assRegs[assRegsCounter[j]].priority} \nspecialty: ${blocks[j].assRegs[assRegsCounter[j]].specialty} \nduration: ${blocks[j].assRegs[assRegsCounter[j]].surDuration}`);
          td.setAttribute('rowspan', `${blocks[j].assRegs[assRegsCounter[j]].surDuration / mcd}`);
          ri = Math.floor(Math.random() * seq.length); // Random Index position in the array
          rs = seq.splice(ri, 1); // Splice out a random element using the ri var
          if (TABLE_CP_REGS.isDark(rs))
            td.style.color = '#FFFFFF';
          //td.style.backgroundColor = '#' + rs;
          td.style.background = `linear-gradient(to left, #${rs} 0% 93%, #${specSeq[blocks[j].assRegs[assRegsCounter[j]].specialty]} 93%)`;
          tdCounter[j] = blocks[j].assRegs[assRegsCounter[j]].surDuration / mcd;
          assRegsCounter[j]++;
          tr.appendChild(td);
          tdCounter[j] -= 1;
        }
        else
         tdCounter[j] -= 1;
      }
      else {
        if (!tdCounter[j]) {
          td = document.createElement('td');
          td.classList.add('empty-block');
          td.setAttribute('rowspan', `${numRows -i}`);
          td.style.backgroundColor = '#F5F5F5';
          tdCounter[j] = numRows -i;
          tr.appendChild(td);
          tdCounter[j] -= 1;
        }
        else
         tdCounter[j] -= 1;
      }
    }
    tbody.appendChild(tr);
  }
}

/**
  * Determines if a color is dark or not
  * @param {String} [color] the to be examined color (in rgb format)
  * @return {Boolean} true if dark, false otherwise
  */
TABLE_CP_REGS.isDark = function( color ) {
  let rgb = parseInt(color, 16);   // convert rrggbb to decimal
  let r   = (rgb >> 16) & 0xff;  // extract red
  let g   = (rgb >>  8) & 0xff;  // extract green
  let b   = (rgb >>  0) & 0xff;  // extract blue

  let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return (luma < 129)
};
